import { Injectable } from '@angular/core';
import { BsApiService, GenericResponseResult, SimpleGlobalModalService } from '@brightside-web/desktop/data-access/core-services';
import { BehaviorSubject, Observable, take, tap } from 'rxjs';
import { Router } from '@angular/router';
import { FirebaseService } from './firebase.service';

export interface PartnerDetails {
  key: string;
  name: string;
  caption: string;
  desc: string;
  summary: string;
  tagline?: string;
  image: string;
  imagePartner: string;
  url: string;
  details: string[][];
}

export interface ExternalPartnerResponse {
  payload: { partners: PartnerDetails[] };
}

export enum ExternalPartnerType {
  LenderAlernatives = 'lender-alternatives',
  FindMoney = 'find-money',
}

@Injectable()
export class ExternalPartnerService {
  apiName = 'api-mobile';

  private _externalPartners$: BehaviorSubject<PartnerDetails[] | undefined> = new BehaviorSubject<PartnerDetails[] | undefined>(
    undefined
  );
  public readonly externalPartners$: Observable<PartnerDetails[] | undefined> = this._externalPartners$.asObservable();

  private _currentPartnerDetails$: BehaviorSubject<PartnerDetails | undefined> = new BehaviorSubject<PartnerDetails | undefined>(
    undefined
  );
  public readonly currentPartnerDetails$: Observable<PartnerDetails | undefined> = this._currentPartnerDetails$.asObservable();

  private readonly _endpoint = '/external-partner';
  private _externalPartnerAttempts = 0;
  private _externalPartnerDetailMaxAttempts = 10;

  constructor(private _bsApiService: BsApiService, private _router: Router, private _modalService: SimpleGlobalModalService) {}

  getPartners(type: string, partnerKey?: string) {
    this._bsApiService
      .get<ExternalPartnerResponse>(this.apiName, `${this._endpoint}?type=${type}`)
      .pipe(
        take(1),
        tap((lenders) => {
          if (lenders.payload.partners && lenders.payload.partners.length > 0) {
            this._externalPartners$.next(lenders.payload.partners);
            if (partnerKey) {
              this.getPartnerDetails(type, partnerKey);
            }
          }
        })
      )
      .subscribe({
        error: (err) => {
          this._modalService.createGenericErrorModal('external_lenders', () => {
            this.getPartners(type, partnerKey);
            this._modalService.clearModal();
          });
        },
      });
  }

  getPartnerDetails(type: string, partnerKey: string) {
    this._externalPartnerAttempts++;

    if (this._externalPartnerAttempts > this._externalPartnerDetailMaxAttempts) {
      this.partnerNotFound();
      return;
    }

    if (this._externalPartners$.value && this._externalPartners$.value.length > 0) {
      const details = this._externalPartners$.value.find((partner) => partner.key === partnerKey);

      if (details) {
        this._currentPartnerDetails$.next(details);
        this._externalPartnerAttempts = 0;
      } else {
        this.partnerNotFound();
      }
    } else {
      setTimeout(() => this.getPartners(type, partnerKey), 100);
    }
  }

  postToExternalPartner(partnerKey: string): void {
    this._bsApiService.post(this.apiName, `${this._endpoint}`, { partnerKey }).subscribe();
  }

  resetExternalPartnerDetails() {
    this._currentPartnerDetails$.next(undefined);
  }

  private partnerNotFound() {
    this._router.navigate(['home']);
  }
}
