import {inject, Injectable} from '@angular/core';

import {AuthService as Auth0Service} from '@auth0/auth0-angular';
import {BehaviorSubject, Observable, of, switchMap} from 'rxjs';
import {catchError, map} from "rxjs/operators";
import {BsHubService} from "../Hub/bs-hub.service";
import {
  ProfileService
} from "@brightside-web/desktop/data-access/shared";

export interface UserAttributesInterface {
  guid: string;
  intercom_external_id: string;
  last_name: string;
  first_name: string;
  phone_number: string;
  email: string;
  locale: string;
  company: string;
  sf_contact_id: string;
}

@Injectable({
  providedIn: 'root'
})
export class BsAuthService {


  private _reloginErrors: string[] = ['Unknown or invalid refresh token.'];
  private _auth0Service: Auth0Service = inject(Auth0Service);
  private _bsHubService: BsHubService = inject(BsHubService);
  private _profileService: ProfileService = inject(ProfileService);

  private _userLoggedInSubject = new BehaviorSubject<boolean>(false);
  private _userLoggedOutSubject = new BehaviorSubject<boolean>(false);

  private _defaultScope= 'openid profile email phone_number read:current_user read:current_user_metadata offline_access'

  private storedAccessToken: string;
  private overrideIsAuthenticated: boolean | null = null;

  constructor() {
    this.isAuthenticated().subscribe(authenticated => {
      if (authenticated) {
        this.notifyUserLoggedIn();
      } else {
        this.notifyUserLoggedOut();
      }
    });
    this._auth0Service.error$.subscribe(
      errors => {
        if (this._reloginErrors.includes(errors.message)) {
          this.login();
        } else {
          /** errors logging in don't always result in the
           *  Auth0 information clearing, so we log out.
           */
          this.logout().subscribe();
        }
      }
    )
  }

  getUserLoggedInEvent(): Observable<boolean> {
    return this._userLoggedInSubject.asObservable();
  }

  getUserLoggedOutEvent(): Observable<boolean> {
    return this._userLoggedOutSubject.asObservable();
  }

  notifyUserLoggedIn() {
    this._userLoggedInSubject.next(true);
    this._userLoggedOutSubject.next(false);
    this._bsHubService.dispatch('bsAuth', { event: 'signedIn' });
  }

  notifyUserLoggedOut() {
    this._userLoggedInSubject.next(false);
    this._userLoggedOutSubject.next(true);
    this._bsHubService.dispatch('bsAuth', { event: 'signedOut' });
  }

  login(prompt: 'login' | 'signup' | 'none' | string = 'login', additionalScope: string = '', redirectRoute?: string): void {
    this.overrideIsAuthenticated = null;
    this._auth0Service.loginWithRedirect({
      authorizationParams: {
        screen_hint: prompt,
        scope: this._defaultScope + ' ' + additionalScope,
        redirect_uri: window.location.origin + '/authenticating',
      },
    });
  }

  logout(): Observable<void> {

    const logoutOptions = {
      logoutParams: {
        returnTo: window.location.origin
      }
    };

    return new Observable<void>((observer) => {
      const logoutSubscription = this._auth0Service.logout(logoutOptions).subscribe({
        next: () => {
          this.overrideIsAuthenticated = null;
          observer.next();
          observer.complete();
        },
        error: (error: any) => {
          observer.error(error);
          console.error('Error logging out', error);
        }
      });
      return () => {
        logoutSubscription.unsubscribe();
      };
    });
  }

  isAuthenticated(): Observable<boolean> {
    if (typeof this.overrideIsAuthenticated === 'boolean') {
      return of(this.overrideIsAuthenticated);
    }
    return this._auth0Service.isAuthenticated$;
  }

  getToken(): Observable<string> {
    return this.isAuthenticated().pipe(
      switchMap(authenticated => {
        if (authenticated) {
          if (this.storedAccessToken) return of(this.storedAccessToken);
          return this._auth0Service.getAccessTokenSilently({
              authorizationParams: {
                scope: 'openid profile email phone_number read:current_user read:current_user_metadata offline_access'
              }
            }
          ).pipe(
            map(token => token ?? ''),
            catchError(error => {
              console.error('Error retrieving token:', error);
              return of('');
            })
          );
        } else {
          return of('');
        }
      })
    );
  }

  getIdTokenClaims() {
    return this._auth0Service.idTokenClaims$;
  }

  fetchUserAttributes(force = false): Observable<UserAttributesInterface> {
    return this._profileService.getProfile(force);
  }

  setAccessTokenForMicroApps(accessToken: string) {
    this.storedAccessToken = accessToken;
    this.overrideIsAuthenticated = true;
    this.notifyUserLoggedIn();
  }

  changePassword() {
    this.login('login', 'change_password', '');
  }
}
