import { Component, OnInit, OnDestroy, Injector, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { RoutingStateService, ToastService } from '@brightside/brightside-ui-services';

import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { DisplayFormControlConfig } from './controls/controls';
import { Settings, AnalyticsAttributesV4 } from '@brightside-web/desktop/data-access/shared';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import {
  IsRunningIOS,
  IsRunningAndroid,
  MessageBusInternalService,
  MessageBusOutgoingEventKey
} from "@micro-core/message-bus";


interface DisplayFormPatchValues {
  [key: string]: string | number;
}

export class DisplayFormAnalytics {
  shown: AnalyticsAttributesV4;
  dismiss: AnalyticsAttributesV4;
  submit: AnalyticsAttributesV4;
}

export class DisplayFormSettings extends Settings {
  constructor(config: any = {}) {
    super(config);

    this.pageFormControlFullWidth = config.pageFormControlFullWidth || false;
    this.pageFormControls = config.pageFormControls || [];
    this.pageFormControlsTitle = config.pageFormControlsTitle || '';
    this.pageFormControlsTitleKey = config.pageFormControlsTitleKey || '';

    this.processSecondaryEvent = config.processSecondaryEvent || null;
    this.processUpdater = config.processUpdater || null;
    this.checkPageFormForceAsValid = config.checkPageFormForceAsValid || null;
  }
  pageFormControlFullWidth?: boolean;
  pageFormControls: DisplayFormControlConfig[];
  pageFormControlsObservable?: Observable<DisplayFormControlConfig[]>;
  pageFormControlsTitle?: string;
  pageFormControlsTitleKey?: string;

  //Returns a routing array to navigate to
  processSecondaryEvent?: () => Observable<string[]>;

  /**
   * This is where you should have the caller process the form data. When this is called the Angular component will provide an Injector and FromGroup class
   * which can be used to send server request for example. Method needs to provide a promise object that will return success.failure when complete
   *
   * @example
   * processUpdater: (injector: Injector, form: FormGroup) => {
   * const responseObservable = new Observable<DisplayFormProcessResponse>((responseSubscriber) => {
   *   const clientService = injector.get(MicroUserCreationClientService);
   *   const updateAddressObservable = clientService.updateAddress(ExtractAddressFromForm(form)).subscribe(
   *     () => {
   *       updateAddressObservable.unsubscribe();
   *
   *       responseSubscriber.next({ status: 'success' });
   *       responseSubscriber.complete();
   *     },
   *     () => {
   *       updateAddressObservable.unsubscribe();
   *
   *       responseSubscriber.next({
   *         status: 'failed',
   *         errorMessage: 'Could not save your updated information. Please try again.',
   *       });
   *       responseSubscriber.complete();
   *     }
   *   );
   * });
   */
  processUpdater?: (injector: Injector, form: FormGroup, translatePipe: TranslatePipe) => Observable<DisplayFormProcessResponse>;
  checkPageFormForceAsValid?: null
}

export interface DisplayFormProcessResponse {
  status: string;
  errorType?: DisplayFormProcessErrorType;
  errorMessage?: string;
  errorMessageTitle?: string;
}

export enum DisplayFormProcessErrorType {
  BANNER = 'BANNER',
  MODAL = 'MODAL',
  SILENT = 'SILENT',
}

@Component({
  selector: 'brightside-web-display-form',
  templateUrl: './display-form.component.html',
})
export class DisplayFormComponent implements OnInit, OnDestroy {
  config: DisplayFormSettings = new DisplayFormSettings({
    fbPage: 'display',
    fbCategory: 'cards',
    fbEventName: '',
    pageTitle: '',
    pageTitleKey: '',
    pageSubTitle: '',
    pageSubTitleKey: '',
    pageCtaLabel: 'BUTTON_CONTINUE',
    pageCtaLabelKey: '',
    pageSecondaryCtaLabel: '',

    pageFormControls: [],

    pageOnForwardCtaPath: [],
  });

  //This will determine if the form should spin up form on init or when manually triggered
  formAutomaticCreateMode = true;

  displayForm: FormGroup;

  processing: boolean;

  pageIsFullScreen: boolean;

  processErrorMessage: string;
  processErrorMessageTitle: string;

  showProcessError: boolean;

  displayFormAnalytics = new DisplayFormAnalytics();

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected injector: Injector,
    protected routingState: RoutingStateService,
    protected toastService: ToastService,
    protected translatePipe: TranslatePipe,
    protected translateService: TranslateService,
    protected changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.checkForConfigViaData();

    if (this.formAutomaticCreateMode) {
      this.buildFormFromConfig();
      this.clearForm();
    }
  }

  ngOnDestroy(): void {
    //We need to clear any initialized values from the config - Weird issue from the
    //nature of the configs generation
    this.config.pageFormControls.forEach((controlConfig: DisplayFormControlConfig) => {
      if (controlConfig.inputOptions?.initialValue !== undefined) {
        controlConfig.inputOptions.initialValue = '';
      }
    });
  }

  private checkForConfigViaData() {
    if (this.activatedRoute?.snapshot?.data?.pageConfig) {
      this.config = new DisplayFormSettings(this.activatedRoute.snapshot.data.pageConfig);
      if (this.config) {
        this.setUpAnalytics();
      }
    }
  }

  private setUpAnalytics() {
    if (this.config.fbEventName) {
      this.displayFormAnalytics.shown = this.config.analyticsAttributes('shown');
      this.displayFormAnalytics.dismiss = this.config.analyticsAttributes('dismiss');
      this.displayFormAnalytics.submit = this.config.analyticsAttributes('tapped');
    }
  }

  protected processControlAndUpdateFormPatch(
    patchInitialValues: DisplayFormPatchValues,
    controlConfig: DisplayFormControlConfig
  ) {
    if (controlConfig.control) {
      //If there is a default initialValue, let's set it.
      if (controlConfig.inputOptions?.initialValue) {
        if (controlConfig.type === 'date') {
          patchInitialValues[controlConfig.key] = controlConfig.inputOptions.initialValue;
          try {
            patchInitialValues[controlConfig.key] = new Date(controlConfig.inputOptions.initialValue).toISOString().split('T')[0];
          } catch (e) {
            const dateArgs = controlConfig.inputOptions.initialValue.toString().split('-');
            patchInitialValues[controlConfig.key] = new Date(Number(dateArgs[2]), Number(dateArgs[0]) - 1, Number(dateArgs[1]), 0,0,0).toISOString().split('T')[0];
          }
        } else {
          patchInitialValues[controlConfig.key] = controlConfig.inputOptions.initialValue;
        }
      }
    }
  }

  protected buildFormFromConfig() {
    const controls: { [key: string]: FormControl } = {};
    const patchInitialValues: DisplayFormPatchValues = {};

    if (this.config.pageFormControls) {
      this.config.pageFormControls.forEach((controlConfig: DisplayFormControlConfig) => {
        this.processControlAndUpdateFormPatch(patchInitialValues, controlConfig);

        if (controlConfig.control) controls[controlConfig.key] = controlConfig.control;
      });
    }

    this.displayForm = new FormGroup(controls);

    //Needed to update this way to make sure the updates take
    this.displayForm.statusChanges.pipe(take(1)).subscribe((status: any) => {
      setTimeout(() => {
        const patchKeys = Object.keys(patchInitialValues);

        if (patchKeys.length > 0) {
          this.displayForm.patchValue(patchInitialValues, { emitEvent: true });
          this.changeDetectorRef.detectChanges();
        }
      }, 1);

      return status;
    });
  }

  protected clearForm() {
    this.displayForm.reset();
  }

  protected handleMoveBackward() {
    this.routingState.popAndNavigateTo(this.config.pageOnBackCtaPath || []);
  }

  protected handleMoveForward() {
    if (this.config.bridgeExitOverride && (IsRunningIOS() || IsRunningAndroid())) {
      MessageBusInternalService.sendOutgoingHubEvent(
        {
          event: MessageBusOutgoingEventKey.ROUTE,
            data: {
              path: this.config.bridgeExitOverride,
            },
        }
      );
    } else {
      this.routingState.popAndNavigateTo(this.config.pageOnForwardCtaPath || []);
    }
  }

  protected handleShowError(result: DisplayFormProcessResponse) {
    if (!result.errorType) result.errorType = DisplayFormProcessErrorType.MODAL;

    if (result.errorType === DisplayFormProcessErrorType.MODAL) {
      this.processErrorMessage = result.errorMessage || '';
      this.processErrorMessageTitle = result.errorMessageTitle || '';
      this.showProcessError = true;
    } else if (result.errorType === DisplayFormProcessErrorType.BANNER) {
      this.toastService.error(this.translateService.instant(result.errorMessage || ''), {
        link: false,
        transient: true,
      });
    }

    this.processing = false;
  }

  doesControlHaveError(key: string): boolean {
    if (this.displayForm) {
      const control = this.displayForm.get(key);

      if (control) {
        return control.touched && control.invalid;
      }

      return false;
    } else {
      return false;
    }
  }

  handleBackClick() {
    this.handleMoveBackward();
    return false;
  }

  handleCtaClick() {
    if (this.processing) {
      return;
    }

    this.processing = true;
    this.showProcessError = false;
    this.processErrorMessage = '';

    if (this.config.processUpdater) {
      const results = this.config.processUpdater(this.injector, this.displayForm, this.translatePipe);

      const defaultErrorMessage = this.translateService.instant('PRIMARY_VERIFICATION_ERROR_MSG_WEB');
      const defaultErrorTitle = this.translateService.instant('ERROR_TITLE_GENERAL');

      results.subscribe({
        next: (result:DisplayFormProcessResponse) => {
          if (result.status === 'success') {
            this.handleMoveForward();
          } else {
            this.handleShowError({
              ...result,
              errorMessage: result.errorMessage || defaultErrorMessage,
              errorMessageTitle: result.errorMessageTitle || defaultErrorTitle,
            });
          }
        },
        error: () => {
          this.handleShowError({
            status: 'failed',
            errorType: DisplayFormProcessErrorType.MODAL,
            errorMessage: defaultErrorMessage,
            errorMessageTitle: defaultErrorTitle,
          });
        }
      });
    } else {
      this.handleMoveForward();
    }
  }

  handleSecondaryCtaClick() {}

  handleLinkClick(path: string[]) {
    this.routingState.navigateTo(path);
  }
}
