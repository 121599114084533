import { Component, OnInit } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ActionDetailsCardConfigInterface } from '@brightside/brightside-ui';
import { TranslateService } from '@ngx-translate/core';
import { Environment } from '@micro-core/environment';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ExternalPartnerService,
  ExternalPartnerType,
  FirebaseService,
  PartnerDetails,
} from '@brightside-web/desktop/data-access/shared';

@Component({
  selector: 'brightside-web-external-lenders-intro',
  templateUrl: './external-lenders-intro.component.html',
  styleUrls: ['./external-lenders-intro.component.scss'],
})
export class ExternalLendersIntroComponent implements OnInit {
  externalPartners$: Observable<ActionDetailsCardConfigInterface[] | undefined>;
  cdnImgUrl: string;
  partnersArray: string[] = [];
  partnersString = '';

  constructor(
    private _externalPartnerService: ExternalPartnerService,
    private _translateSvc: TranslateService,
    private _env: Environment,
    private _router: Router,
    private _route: ActivatedRoute,
    private _analytics: FirebaseService
  ) {
    this.cdnImgUrl = this._env.cdnUrl + 'images/app/';
    this.externalPartners$ = this._externalPartnerService.externalPartners$.pipe(
      map((partners: PartnerDetails[] | undefined) => {
        if (partners) {
          const partnersMap = partners.map((partner) => this.mapToCardConfig(partner));
          this.partnersString = this.partnersArray.join(',');
          this._analytics.logEvent('lender_alternatives_shown', { value: this.partnersString });
          return partnersMap;
        } else {
          return undefined;
        }
      })
    );
  }

  ngOnInit() {
    this._externalPartnerService.getPartners(ExternalPartnerType.LenderAlernatives);
    this._externalPartnerService.resetExternalPartnerDetails();
  }

  private mapToCardConfig(partner: PartnerDetails): ActionDetailsCardConfigInterface {
    this.partnersArray.push(partner.key);
    return {
      title: this._translateSvc.instant(partner.name.toUpperCase()),
      labelImg: this.cdnImgUrl + partner.image + '.png',
      cta: partner.url,
      ctaString: this._translateSvc.instant('LEARN_MORE'),
      details: partner.details.map((detailGroup: string[]) => ({
        title: this._translateSvc.instant(detailGroup[0].toUpperCase()),
        detail: this._translateSvc.instant(detailGroup[1].toUpperCase()),
      })),
      data: partner,
    };
  }

  onCtaClicked(partnerData: PartnerDetails) {
    if (partnerData) {
      this._router.navigate([partnerData.key], { relativeTo: this._route });
    }
  }
}
